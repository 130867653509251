<template>
  <b-overlay :show="show" v-if="!show">
    <div class="fis-form-islem p-4 float-right bg-dark w-100" style="position: fixed; z-index: 10">
      <b-button-group>
        <b-button variant="danger" @click="sabitKuraCevir = !sabitKuraCevir"> Sabit Kura Çevir </b-button>
        <b-dropdown variant="info" right :text="`DİL SEÇ ( ${selectDil(defaultDil)} )`">
          <b-dropdown-item v-for="(dil, index) in diller" :key="index" @click="handlerDilSec(dil.lang)">
            {{ dil.title }}
          </b-dropdown-item>
        </b-dropdown>
        <b-dropdown variant="info" right :text="`${fiyat == 'fiyatli' ? 'FİYATLI' : 'FİYATSIZ'}`">
          <b-dropdown-item @click="handlerYazdir('fiyatli')">Fiyatlı</b-dropdown-item>
          <b-dropdown-item @click="handlerYazdir('fiyatsiz')">Fiyatsız</b-dropdown-item>
        </b-dropdown>
        <b-button variant="warning" @click="handlerPrint"> YAZDIR </b-button>
      </b-button-group>
    </div>
    <table class="table table-striped table-borderless fis-tablosu" witdh="100%">
      <thead>
        <td colspan="2" class="fis-baslik">{{ lang[defaultDil].rezervasyonBilgileri }}</td>
      </thead>
      <thead class="border-top border-bottom">
        <td>
          <img :src="handlerLogoShow(logo.logo)" alt="" width="200" />
        </td>
        <td class="text-right">
          <div>
            <span class="d-block">
              <b>{{ lang[defaultDil].rezervasyonNo }} : </b> {{ form.k_no }}
            </span>
            <span>
              <b>{{ lang[defaultDil].islemTarihi }} :</b> {{ form.islem_tarihi | momentFull }}
            </span>
          </div>
        </td>
      </thead>
      <tbody>
        <tr>
          <td class="border-right" width="50%">
            <table class="table table-bordered">
              <thead>
                <td colspan="2" class="ic-baslik">{{ lang[defaultDil].acenteBilgileri }}</td>
              </thead>
              <tbody>
                <tr class="border">
                  <td>
                    {{ firma.firma_adi }}
                  </td>
                </tr>
                <tr class="border">
                  <td>
                    {{ firma.adres }}
                  </td>
                </tr>
                <tr class="border">
                  <td>
                    {{ firma.telefon }} - {{ firma.e_mail }} <br />
                    <a :href="firma.web_site">{{ firma.web_site }}</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td>
            <table class="table">
              <thead>
                <td colspan="2" class="ic-baslik">{{ lang[defaultDil].misafirBilgileri }}</td>
              </thead>
              <tbody>
                <tr class="border">
                  <td>{{ form.misafir.ad }} {{ form.misafir.soyad }}</td>
                </tr>
                <tr class="border">
                  <td>
                    {{ form.misafir.adres }}
                  </td>
                </tr>
                <tr class="border">
                  <td>{{ form.misafir.telefon }} - {{ form.misafir.e_mail }}</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr class="border-top">
          <td colspan="2">
            <table class="table table-bordered">
              <thead>
                <td colspan="5" class="ic-urun-baslik">{{ lang[defaultDil].rezervasyonUrunBilgileri }}</td>
              </thead>
              <thead>
                <td class="fis-baslik text-left">#{{ lang[defaultDil].urun }}</td>
                <td class="fis-baslik text-right" v-if="fiyat == 'fiyatli'">{{ lang[defaultDil].tutar }}</td>
              </thead>
              <tbody v-if="(form.rezervasyon_toplam && form.sabit_kur == form.secilen_kur) || sabitKuraCevir">
                <template>
                  <UrunSabitKurItem
                    v-for="(urun, i) in form.sepet_urunler"
                    :key="i"
                    :urun="urun"
                    :form="form"
                    :sabitKuraCevir="sabitKuraCevir"
                    :fiyat="fiyat"
                    :lang="lang[defaultDil]"
                    :defaultDil="defaultDil"
                  />
                </template>
              </tbody>
              <tbody v-else-if="(form.kur_sepet_toplam && form.sabit_kur != form.secilen_kur) || !sabitKuraCevir">
                <template>
                  <UrunSecilenKurItem
                    v-for="(urun, i) in form.sepet_urunler"
                    :key="i"
                    :urun="urun"
                    :form="form"
                    :sabitKuraCevir="sabitKuraCevir"
                    :fiyat="fiyat"
                    :lang="lang[defaultDil]"
                    :defaultDil="defaultDil"
                  />
                </template>
              </tbody>
            </table>
          </td>
        </tr>
        <tr class="border-top">
          <td>
            <table class="fis-rez-durum table table-striped">
              <tbody>
                <tr>
                  <td class="font-weight-bold">{{ lang[defaultDil].hotelBilgi }} :</td>
                  <td class="font-weight-bold">{{ form.hotel_bilgi.hotel_adi }} / {{ form.hotel_bilgi.oda_no }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ lang[defaultDil].rezervasyonDurum }} :</td>
                  <td class="font-weight-bold">{{ form.rezervasyon_drm[defaultDil].baslik }}</td>
                </tr>
                <tr>
                  <td>{{ lang[defaultDil].odemeTuru }} :</td>
                  <td>{{ form.odeme_yontemi | odemeYontemi }}</td>
                </tr>
                <tr>
                  <td>{{ lang[defaultDil].odemeBilgisi }} :</td>
                  <td>
                    {{ form.odeme_tipi | odemeTipi }}
                  </td>
                </tr>
                <tr>
                  <td>{{ lang[defaultDil].rezervasyonNotu }} :</td>
                  <td>{{ form.rezervasyon_notu }}</td>
                </tr>
                <tr v-if="form.rezervasyon_iptal_notu">
                  <td>{{ lang[defaultDil].rezervasyonIptalNotu }} :</td>
                  <td>{{ form.rezervasyon_iptal_notu }}</td>
                </tr>
              </tbody>
            </table>
          </td>
          <td v-if="fiyat == 'fiyatli'">
            <UrunSabitKurToplam
              :form="form"
              :lang="lang[defaultDil]"
              :fiyat="fiyat"
              v-if="form.sabit_kur == form.secilen_kur || sabitKuraCevir"
            />
            <UrunSecilenKurToplam :form="form" :lang="lang[defaultDil]" :fiyat="fiyat" v-else />
          </td>
        </tr>
      </tbody>
    </table>
  </b-overlay>
</template>

<script>
import { useRouter } from '@/libs/utils';
import store from '@/store';
import UrunSabitKurItem from './component/yazdir/UrunSabitKurItem.vue';
import UrunSecilenKurItem from './component/yazdir/UrunSecilenKurItem.vue';
import UrunSabitKurToplam from './component/yazdir/UrunSabitKurToplam.vue';
import UrunSecilenKurToplam from './component/yazdir/UrunSecilenKurToplam.vue';
import { computed, defineComponent, onMounted, ref } from '@vue/composition-api';

import lang from './../../../api/helpers/mailFormat/language.json';

export default defineComponent({
  components: {
    UrunSabitKurItem,
    UrunSecilenKurItem,
    UrunSabitKurToplam,
    UrunSecilenKurToplam,
  },
  setup() {
    const expo = {};
    const { route } = useRouter();
    expo.sabitKuraCevir = ref(false);
    expo.show = ref(true);
    expo.sabitKuraCevir = ref(false);
    expo.form = ref({});
    expo.logo = ref({});
    expo.firma = ref({});
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.lang = ref(lang);
    expo.fiyat = ref('fiyatli');

    onMounted(async () => {
      expo.show.value = true;
      const k_no = route.value.params.k_no;
      if (k_no != null) {
        await store.dispatch('firmaBilgiGetir').then((res) => {
          if (res.data.data !== null) {
            expo.firma.value = res.data.data;
          }
        });
        await store.dispatch('logoFaviconGetir').then((res) => {
          if (res.data.data !== null) {
            expo.logo.value = res.data.data;
          }
        });
        await store.dispatch('rezervasyonFindOne', k_no).then((res) => {
          expo.form.value = res;
        });
      }

      expo.show.value = false;

      // if (Object.keys(expo.firma.value).length > 0 && Object.keys(expo.rezervasyon.value).length > 0) window.print();
    });

    // expo.ekstraHizmetVarmi = computed(() => {
    //   return expo.form.value.ekstra_hizmetler.some((x) => x.check == true);
    // });

    expo.handlerLogoShow = computed(() => {
      const fileName = store.getters.getUserFirma.db;
      return (val) => {
        if (val != undefined) {
          const base_url =
            process.env.NODE_ENV === 'development'
              ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
              : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);

          return `${base_url}uploads/${fileName}/logo/${val}`;
        }
      };
    });

    expo.selectDil = computed(() => {
      return (val) => {
        return expo.diller.value.find((x) => x.lang == val).title;
      };
    });

    expo.handlerDilSec = (payload) => {
      expo.defaultDil.value = payload;
    };

    expo.handlerYazdir = (payload) => {
      expo.fiyat.value = payload;
    };

    expo.handlerPrint = () => {
      window.print();
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
.fis-tablosu {
  position: relative;
  top: 90px;
}
table {
  width: 100% !important;
  margin-bottom: 0px;
  thead {
    -webkit-print-color-adjust: exact;
    border: 1px solid #dedede;
    background: #eee;
    font-weight: 600;
  }
  th,
  td,
  tr {
    padding: 5px;
    margin: 0px;
    // border: 1px solid #dedede;
  }
}
.table-borderless {
  border: 1px solid #dedede;
  .fis-baslik {
    -webkit-print-color-adjust: exact;
    background: rgb(237, 237, 237) !important;
    color: #3f3f3f;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    padding: 10px;
  }
  .ic-baslik {
    -webkit-print-color-adjust: exact;
    background: rgb(237, 237, 237) !important;
    color: #3f3f3f;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    padding: 10px;
  }
  .ic-urun-baslik {
    -webkit-print-color-adjust: exact;
    background: rgb(237, 237, 237) !important;
    color: #3f3f3f;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    border-top: 1px solid rgb(221, 221, 221);
    padding: 10px;
  }
}
table.fis-rez-durum {
  -webkit-print-color-adjust: exact;
  float: right;
  text-align: left;
}

table.fis-fiyat-bilgi {
  -webkit-print-color-adjust: exact;
  max-width: 340px;
  float: right;
  text-align: right;
  tbody {
    tr {
      td {
        -webkit-print-color-adjust: exact;
        border: 1px solid #dddddd;
        background: #eee;
        font-weight: bold;
        padding: 8px;
      }
    }
  }
}

@media print {
  .printButtonVisible {
    display: none;
  }
  .fis-form-islem {
    display: none;
  }
  .fis-tablosu {
    position: relative;
    top: 0px;
  }
  @page {
    size: A4;
  }
}
</style>
