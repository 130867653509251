<template>
  <tr class="w-100">
    <td style="vertical-align: middle; width: 30px">{{ index + 1 }}</td>
    <td class="h6 font-weight-bold w-50">{{ misafir.ad }} {{ misafir.soyad }}</td>
    <td class="h6 font-weight-bold w-25">{{ misafir.dogum_tarihi | moment }}</td>
    <td class="text-right font-weight-bold h6 text-danger" style="min-width: 90px">
      {{ misafir.tc_pasaport }}
    </td>
  </tr>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    index: [Number],
    misafir: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const expo = {};

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
