<template>
  <table class="float-right table-striped w-100">
    <tbody>
      <tr class="text-right">
        <td class="text-right font-weight-bold border-0 h6 p-3">{{ lang.urunToplam }} :</td>
        <td class="text-right font-weight-bold border-0 pr-1 h6">
          {{ form.kur_rezervasyon_toplam.urun_toplam | cur }} {{ form.secilen_kur }}
        </td>
      </tr>
      <tr class="text-right" v-if="form.kur_rezervasyon_toplam.ekstra_toplam > 0">
        <td class="text-right font-weight-bold h6 border-0 p-3">{{ lang.ekstraToplam }} :</td>
        <td class="text-right font-weight-bold h6 border-0 pr-1">
          {{ form.kur_rezervasyon_toplam.ekstra_toplam | cur }} {{ form.secilen_kur }}
        </td>
      </tr>
      <tr class="text-right text-danger" v-if="form.kur_rezervasyon_toplam.kupon_indirim > 0">
        <td class="text-right font-weight-bold h6 border-0 p-3">{{ lang.indirimToplam }} :</td>
        <td class="text-right font-weight-bold h6 border-0 pr-1">
          {{ form.kur_rezervasyon_toplam.kupon_indirim | cur }} {{ form.secilen_kur }}
        </td>
      </tr>
      <tr class="text-right text-danger" v-if="form.kur_rezervasyon_toplam.sepet_indirim > 0">
        <td class="text-right font-weight-bold h6 border-0 p-3">{{ lang.sepetIndirim }} :</td>
        <td class="text-right font-weight-bold h6 border-0 pr-1">
          {{ form.kur_rezervasyon_toplam.sepet_indirim | cur }} {{ form.secilen_kur }}
        </td>
      </tr>
      <tr class="text-right" v-if="form.kur_rezervasyon_toplam.iskonto_indirim > 0">
        <td class="text-right font-weight-bold h6 border-0 p-3">{{ lang.ozelIskontoToplam }} :</td>
        <td class="text-right font-weight-bold h6 border-0 pr-1">
          {{ form.kur_rezervasyon_toplam.iskonto_indirim | cur }} {{ form.sabit_kur }}
        </td>
      </tr>
      <tr class="text-right text-primary" v-if="form.kdv_turu == 'haric'">
        <td class="text-right font-weight-bold h6 border-0 p-3">{{ lang.araToplam }} :</td>
        <td class="text-right font-weight-bold h6 border-0 pr-1">
          {{ form.kur_rezervasyon_toplam.ara_toplam | cur }} {{ form.secilen_kur }}
        </td>
      </tr>
      <tr class="text-right" v-if="form.kdv_turu == 'haric'">
        <td class="text-right font-weight-bold h6 border-0 p-3">{{ lang.kdvToplam }} ( % {{ form.kdv_oran }} ) :</td>
        <td class="text-right font-weight-bold h6 border-0 pr-1">
          {{ form.kur_rezervasyon_toplam.kdv_toplam | cur }} {{ form.secilen_kur }}
        </td>
      </tr>
      <tr class="text-right text-primary" v-if="form.kur_rezervasyon_toplam.genel_toplam > 0">
        <td class="text-right font-weight-bold h6 border-0 p-3">{{ lang.genelToplam }} :</td>
        <td class="text-right font-weight-bold h6 border-0 pr-1">
          {{ form.kur_rezervasyon_toplam.genel_toplam | cur }} {{ form.secilen_kur }}
        </td>
      </tr>
      <tr class="text-right" v-if="form.kur_rezervasyon_toplam.on_odeme_toplam > 0">
        <td class="text-right font-weight-bold h6 border-0 p-3">
          {{ lang.onOdemeOran }} ( % {{ form.kur_rezervasyon_toplam.on_odeme_oran }} ) :
        </td>
        <td class="text-right font-weight-bold h6 border-0 pr-1">
          {{ form.kur_rezervasyon_toplam.on_odeme_toplam | cur }} {{ form.secilen_kur }}
        </td>
      </tr>
      <tr class="text-right">
        <td class="text-right font-weight-bold h6 border-0 p-3">{{ lang.tahsilat }} :</td>
        <td class="text-right font-weight-bold h6 border-0 pr-1">{{ form.tahsilat | cur }} {{ form.secilen_kur }}</td>
      </tr>
      <tr class="text-right">
        <td class="text-right font-weight-bold h5 border-0 text-primary p-3">{{ lang.kalanToplam }} :</td>
        <td class="text-right font-weight-bold h5 border-0 pr-1 text-primary">
          {{ kalanBakiye(form.tahsilat) | cur }} {{ form.secilen_kur }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { computed, defineComponent, toRefs } from '@vue/composition-api';
export default defineComponent({
  props: {
    form: { type: Object, required: true },
    lang: { type: Object, required: true },
  },
  setup(props) {
    const expo = {};

    const { form } = toRefs(props);

    expo.kalanBakiye = computed(() => {
      return (tahsil) => {
        let toplam = 0;
        let genel_toplam = form.value.kur_rezervasyon_toplam.genel_toplam;

        toplam = genel_toplam - tahsil;

        return toplam;
      };
    });

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
